/* App.css */

.App {
  text-align: center;
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #ffffff;
  padding: 20px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap; /* Allow items to wrap for smaller screens */
}

.logo-title {
  display: flex;
  align-items: center;
  flex: 1; /* Allow stretching */
  justify-content: center; /* Center items on small screens */
}

.logo {
  height: 50px;
  margin-right: 10px;
}

.title {
  font-size: 24px;
  color: #333333;
  margin: 0;
}

nav {
  margin-top: 0;
  flex: 1; /* Allow stretching */
  display: flex;
  align-items: center;
  justify-content: center; /* Center nav items on small screens */
}

nav a, nav button {
  color: #333333;
  margin: 0 10px;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
}

nav a:hover, nav button:hover {
  color: #0073e6;
}

nav a:focus, nav a:active, nav button:focus, nav button:active {
  outline: none;
  border-bottom: 2px solid #0073e6;
}

main {
  padding: 20px;
  flex: 1; /* Allow main to take available space */
}

/* Responsive styles */

@media only screen and (max-width: 768px) {
  .App-header {
    flex-direction: column;
    align-items: center;
  }

  .logo-title {
    margin-bottom: 10px;
  }

  nav a, nav button {
    margin: 5px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .logo {
    height: 40px;
  }

  .title {
    font-size: 20px;
  }

  nav a, nav button {
    font-size: 14px;
  }
}