/* GridView.css */
/* General and other styles */

.filterElement {
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.filterTitle {
  font-weight: bold;
  text-decoration: underline;
  text-align: left;
  margin-bottom: 10px;
}

.clearButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pageButton {
  margin: 0 5px;
}

/* Table header specific styles */

.sortableHeader {
  cursor: pointer;
  user-select: none;
  background-color: white;
  position: relative;
  padding-right: 24px;
  transition: padding-right 0.3s ease;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hoverIcon {
  display: none;
}

.filterStatusHeader {
  cursor: pointer;
  user-select: none;
}

.filterIcon {
  margin-left: 5px;
}

.MuiTableCell-root {
  border-right: 1px solid #ddd;
  padding: 12px 16px; /* Increase padding for better readability */
}

.MuiTableCell-root:last-child {
  border-right: none;
}

.sortIcon {
  margin-left: 5px;
  position: absolute;
  right: 8px;
}

.sortableHeader:hover .hoverIcon,
.sortableHeader.sorted .hoverIcon {
  display: inline;
}

/* Adding borders for row separation */
.MuiTableRow-root {
  border-bottom: 1px solid #ddd;
}

.MuiTableRow-root:last-child {
  border-bottom: none;
}

.MuiTableRow-root:nth-of-type(even) {
  background-color: #f9f9f9;
}

/* Ensure expanded padding when sorting */
.sortableHeader.sorted {
  padding-right: 24px;
}