/* ReviewExtractionPage.css */

.fullScreenContainer {
    width: 80vw;
    max-width: 70%; /* Ensure it does not exceed 100% */
    height: 100vh;
    padding: 16px; /* Add some padding for better spacing */
    margin: 0 auto; /* Center the container horizontally */
    display: flex;
    flex-direction: column;
    box-sizing: border-box; /* Ensure padding behaves as expected */
  }
  
  .filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .filterElement {
    margin-bottom: 16px;
    flex: 1; /* Allow filters to take up available space */
    margin-right: 8px; /* Even spacing */
  }
  
  .tableContainer {
    flex: 1;
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .table {
    width: 100%;
    flex: 1; /* Ensure table takes available space */
  }
  
  .clearButton {
    margin-top: 16px;
  }
  
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .pageButton {
    margin-left: 8px;
  }